import '../../styles/pages/spring-summer-2023/chloe-the-engineer.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
// import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2023-stories/more-stories"
import { InstagramEmbed } from 'react-social-media-embed';
import YouTube from 'react-youtube';

import Hero from "../../images/spring-summer-2023/chloe-the-engineer/hero.jpg";
import titelGraphic from "../../images/spring-summer-2023/chloe-the-engineer/title-graphic.svg";
import chloe from '../../images/spring-summer-2023/chloe-the-engineer/chloe.jpg';
import chloeBoot from '../../images/spring-summer-2023/chloe-the-engineer/chloe-boot.jpg';
// import WomenInStem from '../../images/spring-summer-2023/chloe-the-engineer/women-in-stem.jpg';
import socialBanner from "../../images/spring-summer-2023/social-banners/chloe-the-engineer.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Alumna Chloe Gharios Breaks Barriers and Stereotypes in STEM';
    var pageDesc = 'A civil engineering alumna is on a mission to empower young women in STEM: ‘You can succeed in engineering and be a girl in pink heels.’';
    var slug = 'chloe-the-engineer';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img src={titelGraphic} alt='Chloe The Engineer Stomps Out Stereotypes' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">Alumna breaks barriers in STEM: ‘You can succeed in engineering and be a girl in pink heels.’</p>
            <span className="animated fadeInDown delay-1s byline">By Debra Cano Ramos / Photos By Matt Gush</span>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className="wrap small">
          
          <p><span className="intro-text">Chloe Gharios Osorio is on a mission</span> to empower young women in STEM.</p>

          <p>It all began with her parents. At a young age, they gave her the confidence to step into a “man’s world” in pink high heels and steel-toe construction boots — and succeed.</p>

          <p>“I am lucky to have grown up with parents who never allowed me to believe that being a girl closed any doors. I could do, and succeed, at whatever I put my mind to as a woman,” says the Cal State Fullerton alumna.</p>

          <figure className='align-outside align-left circle' style={{maxWidth: '45%'}}>
            <img src={chloeBoot} alt="" />
          </figure>
          
          <p>Whether she’s in her high-rise office in downtown Los Angeles, at a jobsite or leading a viral social media movement to break stereotypes in STEM, Osorio declares that women can be girly-girls — and engineers. The self-proclaimed “STEMinist” boldly wears pink, sometimes hot pink, and her work cubicle is bedecked in pink decor.</p>

          <p>“I love pink and anything fun and sparkly. My mom taught me that being feminine should never take away from my professionalism and ability to do a job well,” says Osorio, a civil engineering associate for the Los Angeles Department of Water and Power.</p>

          <p>That encouragement from her mother fueled her determination to become an engineer. Her father, an environmental engineer, introduced her to the world of engineering.</p>

          <p>“My dad would come to my elementary school classroom on career day with cool models and stories about engineering. He and my mom are my inspiration,” she says.</p>

          <blockquote className='align-right align-outside'>
            <p>I am lucky to have grown up with parents who <strong>never allowed me to believe that being a girl closed any doors</strong>.</p>
            <cite>- Chloe Gharios Osorio ’17</cite>
          </blockquote>

          <h2>Shattering Stem Stereotypes</h2>

          <p>Osorio ’17 (B.S. civil engineering) is no stranger to overcoming obstacles and shattering gender stereotypes.</p>

          <p>When she was 8 years old, she wanted to play basketball like her older brother. Since there were no teams for girls in her Long Beach neighborhood, she played on a team with all boys.</p>

          <p>“My mom told me, ‘You are not a girl basketball player, you are just a basketball player, and you can be as good as anyone on the court.’ That has stuck with me throughout my entire education and career, and I apply it to everything I do.”</p>

          <p>When she entered the engineering workforce, some people remarked about her being “too girly” to be an engineer.</p>

          <p>“I realized I needed to be involved in changing those attitudes and showing young girls and women that there is a place for them in STEM if that is where they want to be,” she says.</p>

          <p>To inspire women to break through barriers and become STEM — science, technology, engineering and mathematics — professionals, the millennial who grew up with social media turned to platforms like Instagram and TikTok.</p>

          <InstagramEmbed url="https://www.instagram.com/p/CDeHmLTj09U/" width="100%" className="ig-embed" style={{ maxWidth: 400 }} />
          
          <p>In 2020, she posted a photo of her feet — one foot in a high heel and the other in a construction boot on Instagram @chloetheengineer.</p>

          <p>“The photo went viral, and people commented to make it a challenge. The #stompingoutstereotypes movement was born,” recalls Osorio. “I was surprised by how it resonated with so many people. What this movement means to me is that my femininity, and my STEM career, can coexist.”</p>

          <p>Throughout 2020, women all over the world posted their versions of #stompingoutstereotypes pairing stilettos, ballet slippers and roller skates with construction boots.</p>

          <p>“More recently, Instagram reels have been created,” adds Osorio, who has 11,000 followers. “Now, there are hundreds and hundreds of posts with the hashtag. The movement is alive and well.”</p>

          <p>Osorio also is paving the way for other women by promoting her message of inclusion and diversity at outreach events for K-12 girls.</p>

          <p>“I want to show them that they can be themselves and thrive in STEM.”</p>


          <h2>Solving Real-World Problems</h2>

          <figure className='large align-outside align-right'>
            <img src={chloe} alt="" />
            <figcaption>Chloe Gharios Osorio ’17, a civil engineering associate for the Los Angeles Department of Water and Power, is a self-proclaimed “STEMinist.”</figcaption>
          </figure>

          <p>In college, Osorio was in the President's Scholars program for high-achieving students and a student-athlete on the women’s soccer team. After an undetected heart issue ended her soccer career during her sophomore year, she became involved with College of Engineering and Computer Science student organizations.</p>

          <p>She served as chapter president of the American Society of Civil Engineers during her junior year and was involved with Associated General Contractors of California. In 2021, she was honored by the American Society of Civil Engineers as a <a href="https://www.youtube.com/watch?v=_A8Q2mCGtpc" target='_blank' rel="noreferrer">New Face of Civil Engineering</a> for her early-career accomplishments.</p>

          <p>“Being a woman in an engineering or computer science major can sometimes feel lonely and difficult,” she says. “These organizations were my greatest support in school and where I made some of my best friends.”</p>

          <YouTube
            containerClassName="align-wide video"
            videoId="_A8Q2mCGtpc"
            opts={{
              height: '478',
              width: '850',
              playerVars: {
                rel: 0
              },
            }}
          />

          <p>Osorio built concrete canoes and stabilized retaining walls for collegiate competitions. She attended conferences in places like San Antonio, New York City and Portland.</p>

          <p>“These competitions taught me real-world problem-solving and how to apply engineering principles to a project,” she shares.</p>

          <p>For the past five years, Osorio has worked at the Los Angeles Department of Water and Power on projects to provide clean, safe and reliable electricity through renewable energy sources and conservation.</p>

          <p>“It’s an exciting time to work in the power industry since innovation and new infrastructure are needed to help us achieve our clean energy goals,” she says.</p>

          <p>One day she could be working on a transmission line project, and the next day, on a new distribution station.</p>

          <p>“Engineers can change the lives of thousands of people and entire communities. I find that extremely rewarding — and it’s why I do what I do.” <span className="end-mark"></span></p>

          <p className="learn-more">Support Women in Computer Science and Engineering:<br /><a href="https://give.fullerton.edu/WiCSE" target="_blank" rel="noreferrer">give.fullerton.edu/WiCSE</a></p>

        </article>
        <div className='sidebar-story'>
          <div className='sidebar-story-image'>

          </div>
          <div className='sidebar-story-text'>
            <h2>Empowering Women for the Stem Workforce</h2>

            <p>Over the last decade, the College of Engineering and Computer Science has made strides to close the gender gap through programs that engage, retain and prepare women for the STEM workforce.</p>

            <p>These include:</p>

            <ul>
              <li>Since 2012, the <a href="http://www.fullerton.edu/ecs/wicse/" target='_blank' rel='noreferrer'>Women in Computer Science and Engineering</a> program has provided academic support and an inclusive community to help women succeed in their STEM majors.</li>
              <li>A $325,000 grant from <a href="https://news.fullerton.edu/2021/12/grant-from-bank-of-america-supports-csufs-women-in-computer-science-and-engineering-program/" target='_blank' rel='noreferrer'>Bank of America</a>, coupled with a matching gift from MacKenzie Scott and Dan Jewett, established a nearly $500,000 endowment to triple the number of women served by WiCSE and support students during all four years of their undergraduate education.</li>
              <li>A $650,000 “<a href="https://news.fullerton.edu/2021/03/650000-grant-to-attract-retain-and-graduate-women-in-computer-science/" target='_blank' rel='noreferrer'>Best Practice</a>” grant from the Center for Inclusive Computing at Northeastern University is building a pipeline of community college students to CSUF to boost the number of women in highly skilled computing jobs.</li>
              <li>The <a href="https://news.fullerton.edu/2022/05/governors-may-revise-includes-67-5-million-for-cal-state-fullertons-engineering-and-computer-science-innovation-hub/" target='_blank' rel='noreferrer'>state</a> allocated $67.5 million toward building the new Engineering and Computer Science Innovation Hub. The state-of-the-art instructional and research spaces will help to further attract women to engineering and technical careers.</li>
            </ul>

            <p>This spring, the college launched the design phase of the ECS Innovation Hub. Additional state funding and fundraising are being sought to move from concept to groundbreaking.</p>

            <p>“The ECS Innovation Hub allows us to greatly expand and elevate our academic offerings to meet our region’s workforce demands,” says Dean Susan Barua. “It will provide equitable learning opportunities, enrich pathways to student success and attract a more diverse student body. This in turn will help diversify — both in gender and ethnicity — the engineering and technology workforce.”</p>

            <p>For more information or to donate to the ECS Innovation Hub, contact Michael Karg, executive director of development, at <a href='mailto:mkarg@fullerton.edu'>mkarg@fullerton.edu</a>. <span className="end-mark"></span></p>

          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 